import React from 'react';
import { string, shape, func } from 'prop-types';
import VideoThumbnail from './VideoThumbnail';
import { makeStyles } from '@material-ui/core/styles';
import VideoDialog from '../Modals/VideoDialog';

const useStyles = makeStyles({
  defaultImage: {
    position: 'absolute',
    height: '134%',
    width: '101%',
    top: '-17%',
  },
});

const YOUTUBE_ID_LENGTH = 11;

const Youtube = ({
  imgFluid,
  videoUrl,
  customTriggerElement,
}) => {
  const classes = useStyles();

  const videoId = videoUrl
    .split('v=')[1]
    .substring(0, YOUTUBE_ID_LENGTH);

  const iframe = props => (
    <iframe
      {...props}
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
      frameBorder="0"
      allow="autoplay; encrypted-media;"
      allowFullScreen
      title="youtube-player"
    ></iframe>
  );

  return (
    <>
      {customTriggerElement ? (
        <VideoDialog
          videoIframe={iframe}
          triggerElement={customTriggerElement}
        />
      ) : (
        <VideoThumbnail
          imgFluid={imgFluid}
          defaultImageLink={`https://img.youtube.com/vi/${videoId}/sd2.jpg`}
          defaultImageClasses={classes.defaultImage}
        >
          {iframe}
        </VideoThumbnail>
      )}
    </>
  );
};

Youtube.propTypes = {
  imgFluid: shape(),
  videoUrl: string.isRequired,
  customTriggerElement: func,
};

Youtube.defaultProps = {
  imgFluid: undefined, //TODO: can be changed to a default youtube video image.
  videoUrl: 'https://www.youtube.com/watch?v=hqxsGuCd4jM', //TODO: must be changed.
};

export default Youtube;
