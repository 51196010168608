import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Header from '../components/Header';
import { Grid, Typography } from '@material-ui/core';
import COLORS from '../constants/colors';
import FONTS from '../constants/fonts';
import Youtube from '../components/Video/Youtube';
import { shape, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PageContainer from '../components/Containers/PageContainer';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: 20,
    paddingLeft: 20,
    color: COLORS.WHITE,
    width: '60%',
    maxWidth: 600,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 10,
    },
  },
  videosContainer: {
    marginTop: 35,
    width: '80%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    alignSelf: 'center',
    '&>div': {
      padding: '35px 10px',
      [theme.breakpoints.down('md')]: {
        padding: '15px 8px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px 5px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '20px 0',
      },
    },
    '&>div>div': {
      height: 400,
      boxShadow: `2px 2px 5px ${COLORS.BLACK}99`,
      [theme.breakpoints.down('lg')]: { height: 300 },
      [theme.breakpoints.down('md')]: { height: 250 },
      [theme.breakpoints.down('sm')]: { height: 200 },
    },
  },
  videoTitle: {
    color: COLORS.WHITE,
    height: 27,
    fontSize: FONTS.SIZE.XXS,
    fontStyle: 'italic',
  },
}));

const Training = ({ intl, data }) => {
  const { formatMessage } = intl;
  /*TODO:Change data source graphql.*/
  const siteTitle = data.site.siteMetadata.title;
  const videos = data.allContentfulTrainingVideo.edges;

  const classes = useStyles();
  return (
    <Layout>
      {/*TODO:Improve SEO and Helmet props values */}
      <SEO
        title="HeadzUp Sport AB"
        description="Se video och få tips om hur du kan bli en skickligare ishockeyspelare"
        keywords={['träna', 'ishockey']}
      />
      <Helmet title={siteTitle} />
      <PageContainer>
        <Header secondary>
          {formatMessage({ id: 'training.header' })}
        </Header>
        <Typography className={classes.description}>
          {formatMessage({ id: 'training.description' })}
        </Typography>
        <Grid container className={classes.videosContainer}>
          {videos.map(
            ({ node: { title, youtubeVideoLink } }) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={youtubeVideoLink}
              >
                <p className={classes.videoTitle}>
                  {title}
                </p>
                <Youtube videoUrl={youtubeVideoLink} />
              </Grid>
            )
          )}
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Training.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
  data: shape().isRequired,
};

export default injectIntl(Training);

export const pageQuery = graphql`
  query TrainingQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTrainingVideo(
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          title
          youtubeVideoLink
        }
      }
    }
  }
`;
