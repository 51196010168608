import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Img from 'gatsby-image';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { shape, func, string } from 'prop-types';
import COLORS from '../../constants/colors';
import VideoDialog from '../Modals/VideoDialog';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'relative',
    cursor: 'pointer',
    color: COLORS.WHITE,
    overflow: 'hidden',
    height: '-webkit-fill-available',
    width: '100%',
    display: 'inline-grid',
    '&:hover': {
      color: COLORS.RED,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  playIcon: {
    width: '50%',
    height: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const VideoPopupContainer = ({
  children,
  imgFluid,
  defaultImageLink,
  defaultImageClasses,
}) => {
  const classes = useStyles();

  return (
    <VideoDialog
      triggerElement={props => (
        <Paper
          className={classes.root}
          elevation={0}
          square
          {...props}
        >
          {imgFluid ? (
            <Img
              fluid={imgFluid}
              className={classes.image}
              draggable={false}
            />
          ) : (
            <img
              src={defaultImageLink}
              className={`${classes.image} ${defaultImageClasses}`}
            />
          )}
          <PlayCircleOutlineIcon
            className={classes.playIcon}
          />
        </Paper>
      )}
      videoIframe={iframeProps => children(iframeProps)}
    ></VideoDialog>
  );
};

VideoPopupContainer.propTypes = {
  children: func.isRequired,
  imgFluid: shape(),
  defaultImageLink: string,
  defaultImageClasses: string,
};

VideoPopupContainer.defaultProps = {
  imgFluid: undefined, //TODO: can be changed to a default image.
  defaultImageClasses: '',
};

export default VideoPopupContainer;
