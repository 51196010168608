import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { func } from 'prop-types';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      width: '70%',
      height: '80%',
      maxWidth: 'none',
      background: COLORS.BLACK,
      margin: '0',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
        height: '30%',
      },
    },
  },
  videoIframe: {
    width: '100%',
    height: '100%',
  },
}));

const VideoDialog = ({ videoIframe, triggerElement }) => {
  const classes = useStyles();

  const [
    isVideoPopupVisible,
    setIsVideoPopupVisible,
  ] = useState(false);

  const handleOpen = () => {
    setIsVideoPopupVisible(true);
  };

  const handleClose = () => {
    setIsVideoPopupVisible(false);
  };

  return (
    <>
      {triggerElement({ onClick: handleOpen })}
      <Dialog
        onClose={handleClose}
        open={isVideoPopupVisible}
        className={classes.root}
      >
        {videoIframe({ className: classes.videoIframe })}
      </Dialog>
    </>
  );
};

VideoDialog.propTypes = {
  videoIframe: func.isRequired,
  triggerElement: func.isRequired,
};

export default VideoDialog;
